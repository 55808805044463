<template>
  <div>
    <div class="d-flex mb-3 justify-content-center">
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          1
        </div>
        Create Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          2
        </div>
        Key in Details
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded notActive d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          3
        </div>
        Finalize Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded notActive d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          4
        </div>
        Proceed Payment
      </div>
    </div>
    <validation-observer ref="simpleRules">
      <b-card>
        <div class="d-flex justify-content-center align-items-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="CalendarIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Pick Up Date</h3>
          <div class="w-75">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Pick Up Date"
                rules="required"
              >
                <flat-pickr
                  v-model="form.pickup_datetime"
                  class="form-control mt-1"
                  placeholder="Choose Date"
                  readonly="readonly"
                  :config="{
                    minDate: 'today',
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-center align-items-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="TruckIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Origin Point</h3>
          <div class="w-75">
            <p
              type="text"
              class="form-control mr-2 mt-1 d-flex align-items-center"
            >
              {{ form.origin_point.address }}
            </p>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="HomeIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Sender's Details</h3>
          <div class="w-75">
            <div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's First Name"
                    rules="required"
                  >
                    <input
                      v-model="form.sender_first_name"
                      type="text"
                      class="form-control mr-2"
                      placeholder="First Name"
                      aria-label="First Name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Last Name"
                    rules="required"
                  >
                    <input
                      v-model="form.sender_last_name"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Last Name"
                      aria-label="Last Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Sender's Phone Number"
                  rules="required"
                >
                  <input
                    v-model="form.sender_phone_number"
                    type="text"
                    class="form-control"
                    placeholder="Phone Number"
                    aria-label="Phone Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Unit No."
                    rules="required"
                  >
                    <input
                      v-model="form.sender_unit_number"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Unit No."
                      aria-label="Unit No."
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Address Line 1"
                    rules="required"
                  >
                    <input
                      v-model="form.sender_address_line_1"
                      type="text"
                      class="form-control"
                      placeholder="Address Line 1"
                      aria-label="Address Line 1"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Sender's Address Line 2"
                  rules="required"
                >
                  <input
                    v-model="form.sender_address_line_2"
                    type="text"
                    class="form-control"
                    placeholder="Address Line 2"
                    aria-label="Address Line 2"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Postcode"
                    rules="required"
                  >
                    <input
                      v-model="form.sender_postcode"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Postcode"
                      aria-label="Postcode"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Area"
                    rules="required"
                  >
                    <input
                      v-model="form.sender_area"
                      type="text"
                      class="form-control"
                      placeholder="Area"
                      aria-label="Area"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's State"
                    rules="required"
                  >
                    <select
                      v-model="form.sender_state"
                      class="form-control form-select rounded mr-2"
                    >
                      <option value="" disabled selected>State</option>
                      <option v-for="(state, index) in state" :key="index">
                        {{ state.state_name }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Country"
                    rules="required"
                  >
                    <select
                      v-model="form.sender_country"
                      class="form-control form-select rounded"
                    >
                      <option value="" disabled selected>Country</option>
                      <option v-for="(country, index) in country" :key="index">
                        {{ country.value }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="HomeIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Recipient's Details</h3>
          <div class="w-75">
            <div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's First Name"
                    rules="required"
                  >
                    <input
                      v-model="form.recipient_first_name"
                      type="text"
                      class="form-control mr-2"
                      placeholder="First Name"
                      aria-label="First Name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Last Name"
                    rules="required"
                  >
                    <input
                      v-model="form.recipient_last_name"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Last Name"
                      aria-label="Last Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Recipient's Phone Number"
                  rules="required"
                >
                  <input
                    v-model="form.recipient_phone_number"
                    type="text"
                    class="form-control"
                    placeholder="Phone Number"
                    aria-label="Phone Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Unit No."
                    rules="required"
                  >
                    <input
                      v-model="form.recipient_unit_number"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Unit No."
                      aria-label="Unit No."
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Address Line 1"
                    rules="required"
                  >
                    <input
                      v-model="form.recipient_address_line_1"
                      type="text"
                      class="form-control"
                      placeholder="Address Line 1"
                      aria-label="Address Line 1"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Recipient's Address Line 2"
                  rules="required"
                >
                  <input
                    v-model="form.recipient_address_line_2"
                    type="text"
                    class="form-control"
                    placeholder="Address Line 2"
                    aria-label="Address Line 2"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Postcode"
                    rules="required"
                  >
                    <input
                      v-model="form.recipient_postcode"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Postcode"
                      aria-label="Postcode"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Area"
                    rules="required"
                  >
                    <input
                      v-model="form.recipient_area"
                      type="text"
                      class="form-control"
                      placeholder="Area"
                      aria-label="Area"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's State"
                    rules="required"
                  >
                    <select
                      v-model="form.recipient_state"
                      class="form-control form-select rounded mr-2"
                    >
                      <option value="" disabled selected>State</option>
                      <option v-for="(state, index) in state" :key="index">
                        {{ state.state_name }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Country"
                    rules="required"
                  >
                    <select
                      v-model="form.recipient_country"
                      class="form-control form-select rounded"
                    >
                      <option value="" disabled selected>Country</option>
                      <option v-for="(country, index) in country" :key="index">
                        {{ country.value }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="BoxIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Product Details</h3>
          <div class="w-75">
            <b-row class="mb-2">
              <b-col cols="4">
                <validation-provider
                  #default="{ errors }"
                  name="Weight (kg)"
                  rules="required"
                >
                  <input
                    v-model="form.item_weight"
                    type="text"
                    class="form-control mr-2"
                    placeholder="Weight (kg)"
                    aria-label="Weight (kg)"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  #default="{ errors }"
                  name="Quantity"
                  rules="required"
                >
                  <input
                    v-model="form.quantity"
                    type="text"
                    class="form-control mr-2"
                    placeholder="Quantity"
                    aria-label="Quantity"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  rules="required"
                >
                  <input
                    v-model="form.remarks"
                    type="text"
                    class="form-control"
                    placeholder="Remarks"
                    aria-label="Remarks"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
      <div class="d-flex justify-content-center align-items-center mt-3">
        <b-button
          v-b-modal.modal-center
          variant="gradient-primary"
          class="box-shadow-1 px-5"
          @click="onSubmit"
          >Submit</b-button
        >
      </div>
    </validation-observer>
  </div>
</template>

<script>
import axios from '@axios';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  data() {
    return {
      form: {
        origin_point: '',
        temperature: '',
        sender_state: '',
        sender_country: '',
        recipient_state: '',
        recipient_country: '',
        order_type: 'DHL',
        payment_method: 'CASH',
      },
      optionInput: 0,
      product_category: '',
      optionPackaging: '',
      optionRemarks: '',
      address: [],
      state: [],
      country: [
        {
          title: 'Malaysia',
          value: 'Malaysia',
        },
        {
          title: 'Singapore',
          value: 'Singapore',
        },
      ],
      product: [
        {
          title: 'Frozen Food',
          value: 'Frozen Food',
        },
        {
          title: 'Frozen Meat',
          value: 'Frozen Meat',
        },
        {
          title: 'Fruits',
          value: 'Fruits',
        },
        {
          title: 'Vegetables',
          value: 'Vegetables',
        },
        {
          title: 'Dry Goods',
          value: 'Dry Goods',
        },
        {
          title: 'Others',
          value: 'Others',
        },
      ],
      packaging: [
        {
          title: 'Carton Box',
          value: 'Carton Box',
        },
        {
          title: 'Foam Box',
          value: 'Foam Box',
        },
        {
          title: 'Plastic',
          value: 'Plastic',
        },
        {
          title: 'Crate',
          value: 'Crate',
        },
        {
          title: 'Normal Box',
          value: 'Normal Box',
        },
        {
          title: 'Others',
          value: 'Others',
        },
      ],
      remarks: [
        {
          title: 'Fragile',
          value: 'Fragile',
        },
        {
          title: 'Contains Alcohol',
          value: 'Contains Alcohol',
        },
        {
          title: 'Special Handling',
          value: 'Special Handling',
        },
        {
          title: 'Non Halal Items',
          value: 'Non Halal Items',
        },
        {
          title: 'Others',
          value: 'Others',
        },
      ],
      sample: [
        {
          title: 'Ambiance',
          value: 'Ambiance',
        },
        {
          title: 'Chill',
          value: 'Chill',
        },
        {
          title: 'Frozen',
          value: 'Frozen',
        },
      ],
    };
  },
  mounted() {
    this.address = JSON.parse(localStorage.getItem('userData'));
    this.form.origin_point = this.address.branch;
    this.$http.get('state').then((response) => {
      this.state = response.data.data;
    });
  },
  created() {
    if (this.$route.params.data) {
      this.form = this.$route.params.data;
    }
  },
  methods: {
    onSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.form.product_category == 'Others') {
            this.form.product_category = this.product_category;
          }
          if (this.form.packaging == 'Others') {
            this.form.packaging = this.optionPackaging;
          }
          if (this.form.remarks == 'Others') {
            this.form.remarks = this.optionRemarks;
          }
          this.$router.push({
            name: 'dhl-order-finalize',
            params: { data: this.form, address: this.address },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.icon {
  background-color: #0f4392;
  height: 50px;
  width: 50px;
}

.text {
  width: 15rem;
}

.notActive {
  background-color: rgba(211, 18, 100, 0.58);
}
</style>
